.front {

  #content-header {
    width: 100%;
    max-width: 100%;
    padding: 0;
    position: relative;
    height: 100vh;
    background-position: 50% 0%;
    background-size: auto;
    background-image: url("/assets/PraxisLIVEv600-sm.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: #000;
    @include media($mobile) {
      background-position: 50% 100%;
      background-size: cover;
      background-image: url("/assets/PraxisLIVEv600.jpg");
    }
  }

  #page-title {
    background-color: rgba($black, 0.65);
    width: 100%;
    padding: 2em;
    position: absolute;
    bottom: 0;
    text-align: center;
    p {
      font-style: italic;
    }

    h1 {
      display: none;
    }

    aside {
      display: none;
      @include media($mobile) {
        display: block;
      }
    }
  }

  #content {
    padding-top: 3rem;
    .intro {
      text-align: center;
      p{
        font-style: italic;
      }
      em {
        font-weight: bold;
      }
    }

  }

  .btn-circle {
    width: 2em;
    padding: 0;
    font-size: 2rem;
    border: 2px solid $white;
    border-radius: 100% !important;
    color: white;
    background: transparent;
    transition: background .3s ease-in-out;
    &:hover,
      &:focus {
      outline: none;
      color: white;
      background: rgba($white, 0.4);
    }

  }

}