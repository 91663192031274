/*
*	Navigation
*/
.navigation {
  /*border-bottom: 1px solid $light-gray;*/
  
  $link-color: $white;

  padding: 0 1em;
  margin: 0;
  z-index: 100;
  position: fixed;
  width: 100%;
  background-color: $black;
  
  .site-title {
    float: left;
    h1 {
        color: $white;
        text-decoration: none;
        padding-left: 42px;
        padding-right: 1em;
        margin: 0;
        background-image: url("/assets/icons/favicon-32x32.png");
        background-position: center left;
        background-repeat: no-repeat;
        &:active,
          &:focus,
          &:hover {
          color: darken($link-color, 15%);
        }
    }
  }
  
  /*
  *	Navigation Menu - http://refills.bourbon.io/unstyled/ (see "Navigation")
  */
  // Mobile view
  .navigation-menu-button {
    color: $white;
    display: block;
    float: right;
    margin: 0;
    font-size: 1.5em;
    padding-top: 0.5em;
    padding-right: 0.5em;
    @include media ($tablet) {
      display: none;
    }
  }
  // Nav menu
  .navigation-wrapper {
    @include outer-container;
    @include clearfix;
    position: relative;
  }
  
  nav {
    float: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    @include media ($tablet) {
      float: right;
      /*line-height: 1.5em;*/
    }
  }
  ul.navigation-menu {
    clear: both;
    display: none;
    margin: 0 auto;
    overflow: visible;
    padding: 0;
    width: 100%;
    @include media ($tablet) {
      display: block;
      margin: 0;
      padding: 0;
    }
    &.show {
      display: block;
    }
  }

  li.nav-link {
    display: block;
    text-align: right;
    width: 100%;
    padding: 0.25em 0;
    @include media ($tablet) {
      background: transparent;
      display: inline;
      text-decoration: none;
      width: auto;
    }
    a {
      font-size: 1.4em;
      display: inline-block;
      text-decoration: none;
      font-family: $heading-font-family;
      @include media ($tablet) {
        padding-right: 1.5em;
      }
      color: $link-color;
      &:active,
        &:focus,
        &:hover {
        color: darken($link-color, 15%);
      }
    }
    &:last-child a {
      @include media ($tablet) {
        padding-right: 1em;
      }
    }
  }
}
