.community {
  #content-header {
    width: 100%;
    max-width: 100%;
    padding: 0;
    position: relative;
    height: 60vh;
    background-position: 50% 80%;
    background-size: cover;
    background-image: url("/assets/community.jpg");
    @include media($tablet) {
      background-attachment: fixed;
      height: 70vh;
    }
  }

  #page-title {
    width: 100%;
    padding-top: 0;
    position: absolute;
    bottom: 0;
    h1, p {
      background-color: rgba($black, 0.75);
    }
  }
  
  #content section {
    margin-top: 2em;
    margin-bottom: 2em;
  }

}
/*.community {
  background-image: url("/assets/community.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 90%;
  background-attachment: fixed;
  
  main {
    @include outer-container;
    width: 100%;
    background-color: rgba($black, 0.7);
    text-align: center;
  }
  
  #content section {
    margin-top: 2em;
    margin-bottom: 2em;
  }

}*/