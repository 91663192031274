.download {
  /*main {*/
  background-image: url("/assets/downloads-bg-sm.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  @include media($tablet) {
    background-attachment: fixed;
    background-image: url("/assets/downloads-bg.jpg");
  }
  /*}*/

  main {
    @include outer-container;
    width: 100%;
    text-align: center;
    background-color: rgba($black, 0.4);
  }

  #content {
    min-height: 100%;
    section {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
  
  .downloads-info {
    font-size: 0.9em;
  }
  
  .type-arch {
    font-size: 0.75em;
  }

  .donation {
    padding: 1em;
    font-size: 0.9em;
    background-color: rgba(crimson, 0.2);
  }
  
}
