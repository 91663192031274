// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Exo:300|Roboto:400,400i,700,700i');

// Typography
$base-font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif; // $helvetica;
$heading-font-family: "Exo", "Helvetica Neue", "Helvetica", "Arial", sans-serif; // $base-font-family;

// Font Sizes
$base-font-size: $em-base; // 16px
$base-font-weight: normal;
$heading-font-weight: normal;

// Adjust modular scale ratio
$modular-scale-ratio: $minor-third;

// Line height
$base-line-height: 1.8;
$heading-line-height: 1.8;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * $em-base;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$dark-gray: #0a0a0a;
$medium-gray: #444;
$light-gray: #aaa;
$blue: lighten(#5d8eff, 10%);
$white: #fff;
$black: #000;

// Font Colors
$base-background-color: $dark-gray;
$base-font-color: $white;
$base-font-muted-color: $light-gray;
$action-color: $blue;
/*$highlight-color: tint($action-color, 33%); #c3d5ff  */
$highlight-color: lighten($action-color, 20%);

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Screen sizes
$mobile: 640px;
$tablet: 960px;

$max-width: rem(1200);
$medium-screen: $mobile;
$large-screen: $tablet;

$animation-time: 0.3s;